<template>
  <TheHeader></TheHeader>
  <slot />
</template>

<style>
body {
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  width: 100vw;
  max-height: 100%;
  height: 100vh;
  background-image: url("/images/jpg/back_back.jpg");
}
</style>