<script setup>
import { useBalanceStore } from "@/stores/balance";

const balanceStore = useBalanceStore();

function closeStore() {
  Android.closeWebShop()
}
</script>

<template>
  <div class="counters">
    <div class="counter coins">
      <p>{{ balanceStore.balance.coins }}</p>
    </div>
    <div class="counter gems">
      <p>{{ balanceStore.balance.gems }}</p>
    </div>
    <div @click="closeStore" class="exit">
      <img src="/images/png/btn_close_big.png" alt="">
    </div>
  </div>
</template>

<style lang="scss" scoped>
.counters {
  padding-top: 5px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  align-items: center;

  .coins {
    background-image: url(/images/png/ShopBtn.png);
    background-size: cover;
  }

  .gems {
    background-image: url(/images/png/ShopBtnRubin.png);
    background-size: cover;
  }

  .counter {
    aspect-ratio: 192/81;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: clamp(100px, 40%, 13vw);
    // height: clamp(25px, 100vh, 5vh);
    // // width: 9vw;

    // // height: 50px;
    // border-radius: 50vw;
    // border: 2px solid rgb(203, 152, 57);
    p {
      font-weight: 500;
      color: white;
      font-size: clamp(1em, 100vw, 2vw);
      margin-top: 1.3vw;
      margin-left: 3.5vw;
    }
  }

  .exit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    z-index: 100;

    img {
      aspect-ratio: 55/56;
      width: clamp(25px, 4vw, 55px);
      height: clamp(25px, 4vw, 55px);
    }
  }
}
</style>

